import { Text } from '@mantine/core';
import QuoteImg from '../../img/testimonial.svg';

export function Testimonial({ text, source }) {
  return (
    <>
      <img
        src={QuoteImg}
        style={{ margin: "0 auto", width: '30%', display: 'block' }}
        alt="Testimonial" />
      <Text my={20} align="center" fz="sm">{text}</Text>
      <Text weight={700} align="center" fz="sm">- {source}</Text>
    </>
  );
}
