// import { useEffect } from 'react';
import { 
  Title, 
  Container, 
  // Modal, 
} from '@mantine/core';
//import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useMediaQuery } from '@mantine/hooks';
import LPHero from '../modules/lp/LPHero';
import LPCategories from '../modules/lp/LPCategories';
import Pricing from '../modules/lp/Pricing';
import WorkWith from '../modules/lp/WorkWith';
import WhyPS from '../modules/lp/WhyPS';
import Testimonials from '../modules/lp/Testimonials';
import LPFooter from '../modules/lp/LPFooter';
import EventSearch from '../components/EventSearch';
// import PromoModal from '../modules/lp/PromoModal';
import CookieDrawer from '../modules/lp/CookieDrawer';
// import { cookie } from '../lib/helpers';

const categories = ['arts', 'comedy', 'music', 'theatre'];

export default function LP() {
  const isMobile = useMediaQuery("(max-width: 50em)");
  // const [opened, {open, close}] = useDisclosure(false);

  // const cookieValue = cookie('promo');

  // const hideModal = () => {
  //   document.cookie = cookie('promo', '1;SameSite: None; max-age=86400');
  //   close();
  // }

  // useEffect(() => {
  //   if (cookieValue === undefined)  open(); 

  // }, [cookieValue, open]);

  return (
    <>
      {/* <Modal 
        opened={opened} 
        onClose={hideModal} 
        centered 
        withCloseButton={isMobile}
        size={isMobile ? "75%" : "55%"}
        trapFocus={false}
        padding="0"
      >
        <PromoModal isMobile={isMobile} /> 
      </Modal>       */}
      <LPHero />
      <Container mt={20}>
        <Title mb={10} border={2}>Search for events</Title>
        <EventSearch />
      </Container>
      <LPCategories categories={categories} />
      <Pricing isMobile={isMobile} /> 
      <WorkWith isMobile={isMobile} />
      <Testimonials isMobile={isMobile} />
      <WhyPS />
      <LPFooter isMobile={isMobile} />
      <CookieDrawer isMobile={isMobile} />
    </>
  );
}
